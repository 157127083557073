import { BellOutlined, CustomerServiceOutlined, MenuOutlined } from '@ant-design/icons';
import { Badge, Button, Drawer, Dropdown, Layout, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { NotificationView } from './NotificationView';
import { Client } from '@stomp/stompjs';

import logo from "../../assets/silvergold-header.png"

import "../../styles/CustomHeader.css"
import { SideMenu } from './SideMenu';
import { useFetch } from '../../hooks/useFetch';
import { Link } from 'react-router-dom';
import { authFetch } from '../../common/request/authFetch';
const { Header } = Layout;

const CustomHeader = (props) => {

    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [isMenuVisibleNot, setIsMenuVisibleNot] = useState(false);
    const [profile, setProfile] = useState(false);
    const [, setIsPublic] = useState(false);

    const [notifications, setNotification] = useState();
    const [newNotification, setnewNotification] = useState(true);
    const [updateHeader, setUpdateHeader] = useState(false);
    const [updateData, setUpdateData] = useState(false);


    useFetch(`${process.env.REACT_APP_API}/users/principal/profile`, {}, [updateHeader], (data) => {
        setProfile(data);
        if ("ACTIVE" === data.status) {
            setIsPublic(true);
        }
    });

    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/client/notifications/last`, {}, [updateData]);

    const updateNotifications = async () => {

        await authFetch(`${process.env.REACT_APP_API}/client/notifications`, { method: 'PATCH' }).then(() => {
            setUpdateData(!updateData);
        })
    }


    useEffect(() => {

        const client = new Client({
            brokerURL: `${process.env.REACT_APP_NOTIFICATIONS}/ws`,
            onConnect: () => {
                client.subscribe('/topic/notifications', (message) => {
                    setUpdateData(!updateData);
                });
            },
        });

        client.activate();

    }, []);



    const notificationOverlay = () => {

        return (
            <div>
                <div className='accountOverlay-notifications'>
                    <div >
                        <div className='notifications-list'>
                            {storesData ?
                                <><NotificationView notification={storesData}></NotificationView></> : <Spin className='spinner' />
                            }

                            {/* <NotificationView notification={notifications}></NotificationView> */}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Link onClick={() => { setIsMenuVisibleNot(false) }} to='/notificaciones'>Ver más</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    const ShowMenu = (props.isAuthenticated && props.viewHeader === undefined) || (props.viewHeader && props.isAuthenticated);




    return (
        <>
            {props.viewHeader === true ?
                <Header className='headerComponent' >

                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                        <MenuOutlined onClick={() => { setIsMenuVisible(true) }} />
                        <img src={logo} style={{ width: '100px' }} />
                    </div>
                    {/* {ShowMenu && <div style={{ display: 'flex', gap: '20px' }}>
                        <CustomerServiceOutlined />
                        <div >
                            {/* {newNotification ? <div className='circle-notification'></div> : null} */}

                            {/* <Badge color='orange' showZero={false} count={storesData?.notReaded} size="small">
                                <Dropdown open={isMenuVisibleNot} onOpenChange={setIsMenuVisibleNot} overlay={notificationOverlay} trigger={['click']}>
                                    <BellOutlined style={{ fontSize: '18px' }} onClick={() => { updateNotifications() }} />
                                </Dropdown>
                            </Badge> */}

                            {/* <Dropdown open={isMenuVisibleNot} onOpenChange={setIsMenuVisibleNot} overlay={notificationOverlay} trigger={['click']}>
                                <BellOutlined style={{ fontSize: '18px' }} />
                            </Dropdown> 

                        </div>
                    </div>} */}
                </Header >
                : null}

            {isMenuVisible && <SideMenu setIsMenuVisible={setIsMenuVisible} isMenuVisible={isMenuVisible} />}
        </>

    )
};

export default CustomHeader;