// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Image } from 'antd';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../../styles/SlugInfo.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { useState } from "react";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";


export default function SwiperCarousel({mediaItems }) {

  const [prevEl, setPrevEl] = useState(null)
  const [nextEl, setNextEl] = useState(null)

  return (
    <>
  
      <div style={{ height: '350px', position: 'relative' }}>
        <Swiper

          pagination={true} modules={[Pagination]} 
        >
          {
            mediaItems.map((mediaItem, index) => {
              return (
                <SwiperSlide >
                  <Image
                    key={index}
                    src={mediaItem.src || mediaItem}
                    className="swiper-slide-img"
                  />
                </SwiperSlide>
              )
            })
          }
        </Swiper>
      </div>
    </>
  );
}
