import { Breadcrumb, Table } from "antd";
import contractData from "../db.json"
import formatNumber from "../utils/formatNumber";
import { CaretDownOutlined, CaretUpOutlined, RightCircleOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { EntityLabel } from "../components/atoms/EntityLabel";

export const Inversion = () => {

    const { contractId } = useParams();

    

    const columnsMock = [
        {
            title: 'Total',
            dataIndex: 'dates',
            render: (_, record) => (
                <>

                    <>
                        <h3>{parseFloat(record.revenue).toFixed(2)}€</h3>
                        <span>{record.start}</span>
                    </>

                </>
            ),
        },
        {
            title: 'Inicio',
            dataIndex: 'payments',
            render: (_, record) => (
                <>
                    {record.availableMoney ? <div><b>{record.availableMoney}€</b></div> : <>-</> }
                    {record.investedMoney > 0 && <div style={{ color: 'grey' }}>{record.investedMoney}€</div>}
                </>
            ),
        },
        {
            title: 'Lote',
            dataIndex: 'status',
            render: (_, record) => {
                return <div>
                    {record.currentUnits ? <div style={{ display: 'flex', gap: '10px' }}>
                        <div><b>{record.currentUnits} uds</b></div>
                        <div><b>{record.currentUnitsMoney}€</b></div>
                    </div> : <>-</>}
                    <div style={{ display: 'flex', gap: '10px' }}>
                        {record.soldUnits > 0 && <div style={{ color: 'grey' }}>{record.soldUnits} uds</div>}
                        {record.soldUnitsMoney > 0 && <div style={{ color: 'grey' }}>{record.soldUnitsMoney}€</div>}
                    </div>
                </div>
            },
        },
        {
            title: 'Fin',
            dataIndex: 'revenue',
            render: (_, record) => (
                <>
                    <div>
                        {record.soldMoney ? <b>{record.soldMoney}€</b> : <>-</>}
                        <div>{(record.soldPercentage !== 0) && (
                            (record.soldPercentage > 0) ? <div style={{ color: 'green' }}><CaretUpOutlined />{record.soldPercentage}%</div> : <div style={{ color: 'red' }}><CaretDownOutlined />{record.soldPercentage}%</div>
                        )}

                        </div>
                    </div>
                </>
            ),
        },
    ];

   
    return (
        <div className="general-container">
            <Breadcrumb
            style={{ fontSize: '20px', marginBottom:'20px' }}
            separator=">"
            items={[
                {
                    title: <Link to="/contratos">Mis contratos</Link>,
                },
                {
                    title: <Link to={`/contratos/contrato/${contractId}`}><EntityLabel prefix="Contrato" id={contractId} /></Link>,
                },
                {
                    title: 'Inversion',
                },
            ]}
        />
 <Table style={{ marginBottom: '20px' }} size='small' dataSource={contractData.investmentResponseList} columns={columnsMock} rowKey={'id'} loading={false} />        </div>
    )
}