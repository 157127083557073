import { Button, Card, Col, Divider, Progress, Row, Tag, Tooltip } from "antd"
import { PriceTag } from "../components/atoms/PriceTag"
import { Link } from "react-router-dom"
import "../styles/Contracts.css"
import { EuroOutlined, GoldOutlined, SyncOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch"
import { useEffect, useState } from "react"
import { useAuth } from "../store/AuthContext/AuthContext";
import Marquee from 'react-fast-marquee';
import moment from "moment";
import { formatPrice } from "../utils/formatPrice";
import PercentageCustom from "../components/atoms/PercentageCustom";
import { INVESTMENT_PLAN } from "../common/constants/investmentPlan";
import { INVESTMENT_TYPE } from "../common/constants/investmentType";
import { INVESTMENT_STATUS } from "../common/constants/investmentStatus";

export const Contracts = () => {

    const { data: dailyData } = useFetch(`${process.env.REACT_APP_API}/market`, {}, []);

    const { data: contracts } = useFetch(`${process.env.REACT_APP_API}/contractsClient`, {}, []);

    const { data: offers } = useFetch(`${process.env.REACT_APP_API}/productOffers/active`, {}, []);

    const { profile, setProfile } = useAuth();

    const [randomIndex, setRandomIndex] = useState(1);

    useEffect(() => {
        const newIndex = Math.floor(Math.random() * offers.length);
        setRandomIndex(newIndex);
    }, [offers]);


    useFetch(`${process.env.REACT_APP_API}/appClient`, {}, [], (data) => {
        setProfile(data);

    });


    return (<div className="general-container">

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <h1 style={{ textAlign: 'center' }}>¡Hola, {profile.name}!
            </h1>
            <span class="material-symbols-outlined" style={{ color: "rgb(196, 136, 8)", fontSize: '30px' }} >
                waving_hand
            </span>
        </div>


        <Marquee speed={30} gradient={false}>
            <div className="ticker">
                <ul className="ul-ticker ">
                    {dailyData.map((m) => {
                        return <li className="ul-ticker-li" key={m.metal}>
                            <span>{m.metalName}</span>
                            <PercentageCustom increment={m.increment} />

                        </li>
                    })} </ul>
            </div>

        </Marquee>

        {offers.length > 0 ?

            <Card size="small" style={{ background: "var(--accent)", margin: '20px 0px' }} >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <b >{offers[randomIndex].name}</b>
                    <Divider style={{ backgroundColor: 'black', margin: '15px 0px' }} />
                    <small >{offers[randomIndex].description}</small>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ fontSize: '11px', fontWeight: '600' }}><Link style={{ color: 'black' }} to={'/contratos/tipos'}>VER MÁS {">"}</Link></p>
                </div>
            </Card>

            : null
        }


        <h2>Mis Contratos</h2>

        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '10px' }}>
            {contracts.length > 0 ? contracts.map((ct) => {
                return (
                    <Card key={ct.id}
                        bodyStyle={{ padding: '10px' }}
                        style={{ border: '1px lightgrey solid' }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'space-between', position: 'relative', marginBottom: '15px' }}>
                            <div>
                                <h4 style={{ color: 'grey' }}>{ct?.contract?.contractInfo?.typeDescription}</h4>
                                <div style={{display:'flex', alignItems:'center'}}>
                                    <PriceTag size={10} price={ct?.contract?.contractInfo?.revenue} />
                                    <p style={{
                                        fontWeight: 600, fontSize: 10,
                                        color: ct?.contract?.benefit !== undefined && ct?.contract?.benefit !== 0
                                            ? ct?.contract?.benefit > 0
                                                ? 'green'
                                                : 'red'
                                            : 'inherit'
                                    }}>{(ct?.contract?.benefit).toFixed(2)}%</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <small>
                                    {moment(ct?.contract?.contractInfo?.start).format('D [de] MMM[ de] YYYY')}
                                </small>
                                {ct?.contract?.contractInfo?.investmentPlan !== INVESTMENT_PLAN.TOTAL_FREEDOM &&

                                    <div style={{ width: '100px', position: 'absolute', top: '8px' }}>
                                        <Progress showInfo={false} size='small' percent={ct?.contract?.contractInfo?.progress} />
                                    </div>}
                            </div>
                        </div>


                        <Row style={{ justifyContent: 'space-between', display: 'flex' }}>
                            <Col span={10} style={{ display: 'flex', flexDirection: 'column' }}>
                                <b>Saldo</b>
                                <div style={{ display: 'flex', flexDirection: 'column', margin: '0px 10px' }}>
                                    <p style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><EuroOutlined />{ct?.contract?.contractInfo?.cashAccount}€</p>
                                    <Tooltip placement="leftBottom" title={`${ct?.contract?.contractInfo?.units} lingotes`}><p style={{ display: 'flex', alignItems: 'center', gap: '5px', width: 'fit-content' }}><GoldOutlined />{ct?.contract?.contractInfo?.unitsCostAccount}€</p></Tooltip>
                                </div>

                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                                {INVESTMENT_TYPE[ct?.lastPendingInvestment?.type] ? (
                                    <Tag color={INVESTMENT_TYPE[ct?.lastPendingInvestment?.type].color} style={{ borderRadius: '8px', minWidth: '130px', textAlign: 'center' }}>
                                        {INVESTMENT_STATUS[ct?.lastPendingInvestment?.status] && (
                                            ct?.lastPendingInvestment?.status === 'DONE' ?
                                                <span style={{ marginRight: '5px' }}><CheckCircleOutlined /></span>
                                                :
                                                <span style={{ marginRight: '5px' }}><SyncOutlined spin /></span>)}
                                        {INVESTMENT_TYPE[ct?.lastPendingInvestment?.type].name} {formatPrice(ct?.lastPendingInvestment?.revenue, 2)}€
                                    </Tag>)
                                    : <Tag style={{ borderRadius: '8px', minWidth: '130px', textAlign: 'center' }}>Sin novedad</Tag>
                                }
                            </Col>
                        </Row>

                        <Row justify='end'>
                            <Col >
                                {/* <p style={{ fontSize: '11px', fontWeight: '600' }}><Link to={`contrato/${ct?.contract?.id}`}>VER MÁS {">"}</Link></p> */}
                                <Button size='small' style={{ color: 'var(--dark-accent)', borderColor: 'var(--dark-accent)', fontSize: '11px', backgroundColor:'transparent' }}><Link to={`contrato/${ct?.contract?.id}`}>VER MÁS</Link></Button>

                            </Col>
                        </Row>
                    </Card>
                )
            }) : <p style={{ margin: '5px' }}>Sin contratos</p>}

        </div>


    </div>
    )
}