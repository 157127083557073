import React, { useState } from 'react';
import '../styles/Login.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Card, Form, Input, message, Modal } from 'antd';
import { useAuth } from '../store/AuthContext/AuthContext';
import img from "../assets/logos_silvergold_1_Mesa_de_trabajo_1.png"
import { MailOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { authFetch } from '../common/request/authFetch';

export const Login = () => {

  const [user, setUser] = useState('');
  const [loading, setLoading] = useState(false);
  const [errortext, setErrortext] = useState('');

  const [formAuthError, setFormAuthError] = useState(false)
  const [serverError, setServerError] = useState({})
  const location = useLocation()
  const navigate = useNavigate();
  const { logIn } = useAuth()

  const [form] = Form.useForm();

  const sendRecoverEmail = async () => {
		return await authFetch(`${process.env.REACT_APP_API}/users/${user}/recover`, { method: 'POST' })
			.then((isOk) => {
				if (isOk) {
					return message.success(`Correo enviando a ${user}, revise su bandeja de entrada`);
				} else {
					return message.error(`Problema enviando correo a ${user}, compruebe que el correo es correcto`);
				}
			}
			);
	}

  const onSubmit = (data) => {
    const state = location.state
    return logIn(data).then((res) => {
      if (res?.success) {
        setServerError({})
        setFormAuthError(false);

        return navigate(state?.from?.pathname ?? '/contratos');
      }
      if (res?.statusCode !== 401) {
        return setServerError({
          statusCode: res?.statusCode,
          message: res?.message
        })
      }
      return setFormAuthError(true)
    })
  };

  return (
    <div className="mainBody">
     
    <div class="login-general-top"></div>
    
      <div style={{top:'10%', position: 'absolute', display:'flex', flexDirection:'column'}}>
        <h1 style={{color:'white', marginTop:'0px', fontFamily: 'var(--font-family)', fontSize:'45px'}}>SilverGold</h1>
        <p style={{color:'white', marginTop:'0px', fontFamily: 'var(--font-family)'}}>
          SilverGold Patrimonio
        </p>
      </div>

      {/* <div className="logoContainer">
        <img
          src={require('../assets/SG_patrimonio.png')}
          alt="Logo"
        />
      </div> */}

      <div style={{marginTop:'10%'}}>
        <h2>Iniciar Sesión</h2>

        <Form
          layout='vertical'
          form={form}
          onFinish={onSubmit}
          autoComplete='off'
        >
          <Form.Item
            label='Usuario'
            name='username'
            validateTrigger='onFinish'
            rules={[{
              required: true,
              type: 'email',
              message: 'El campo usuario debe ser un correo válido'
            }]}
          >
            <Input
              style={{ backgroundColor: 'var(--accent)' }}
              placeholder='Introduce el correo electrónico'
              onChange={(e) => setUser(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label='Contraseña'
            name='password'
            rules={[{
              required: true,
              message: 'La contraseña es un campo obligatorio'
            }]}
          >
            <Input.Password
              style={{ backgroundColor: 'var(--accent)' }}
              placeholder='Introduce la contraseña'
            />
          </Form.Item>
          {/* {errortext !== '' && <p className="errorTextStyle">{errortext}</p>}
          <Link to='/contratos'><button className="buttonStyle" 
          //onClick={handleSubmitPress}
          >
            Iniciar Sesión
          </button>
          </Link> */}
          <Button className='buttonStyle' htmlType="submit">
            Iniciar Sesión
          </Button>          
        </Form>        
          <div>
            <br/>
            <small>Si tienes email de invitación, <Link to='/register'><b style={{fontSize:'14px', color:'var(--black)'}}>Regístrate</b></Link></small>
          </div>
        </div>
        <div style={{marginTop:'10px'}}>
        {/*
					user ?
          <a style={{color:'black'}}
							onClick={() => {
								Modal.confirm({
									title: <p>¿Enviar correo de recuperación a <b>{user}</b>?</p>,
									content: <p>En este correo que enviaremos tendrás un link desde el que podrás después restablecer tu nueva contraseña</p>,
									okText: 'Enviar',
									cancelText: 'Cancelar',
									icon: <MailOutlined />,
									onOk: () => sendRecoverEmail()
								})
							}}
						>
							¿Olvidaste la contraseña?
						</a>
						:
						<a style={{color:'black'}}
							onClick={
								() => {
									Modal.info({
										title: 'Primero introduce un correo válido en el formulario',
										icon: <InfoCircleOutlined />
									})
								}
							}
						>
							¿Olvidaste la contraseña?
						</a>
            */}
        {
          formAuthError && <Alert
            style={{ marginTop: '12px', borderRadius: '10px' }}
            type='error'
            message='Usuario o contraseña incorrectos'
            showIcon
          />
        }        
      </div>
      <div style={{position:'absolute', bottom:10, right:10}}>
          <img src={img} style={{width:'80px'}} />
        </div>
    </div>
  );
};

