import { Card, Switch } from "antd"
import { RightOutlined } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch";

export const InvoiceInformation = () => {

    const { data } = useFetch(`${process.env.REACT_APP_API}/appClient`, {}, []);

    return (
        <div className="general-container">
            <h1>Datos de facturación</h1>
            <div style={{display:'flex', flexDirection:'column', gap:'20px', marginTop:'20px'}}>
            <Card>
                <h2>Datos bancarios</h2>
                <div style={{margin:'15px 0px'}}>
                    <b style={{color:'var(--primary)'}}>Número de cuenta</b>
                    <p>{data?.accountNumber}</p>
                    {/* <b style={{color:'var(--primary)'}}>CAMBIAR NÚMERO DE CUENTA <RightOutlined /> </b>  */}

                </div>
            </Card>
            <Card>
                <h2>Información de facturación</h2>
                <div style={{margin:'15px 0px'}}>
                    <b style={{color:'var(--primary)'}}>Dirección de facturación</b>
                    <p>{data?.invoiceAddress}</p>
                    {/* <b style={{color:'var(--primary)'}}>CAMBIAR DIRECCIÓN <RightOutlined /> </b>  */}
                </div>

                {/* <div>
                    <b style={{color:'var(--primary)'}}>Factura electrónica</b>
                    <div style={{display:'flex', justifyContent:'space-between'}}>
                    <p>Recibir factura electrónica por email.</p>
                    <Switch defaultChecked/>
                    </div>
                </div> */}
            </Card>
            </div>
        </div>
    )
}