import axios from 'axios';
import { useState } from 'react';
import {
	getTokenForStorage, removeToken, storeToken
} from '../../store/storage';


export const useAuthProvider = () => {
	const startingToken = getTokenForStorage();
  	const [isAuth, setIsAuth] = useState(!!startingToken);
	const [token, setToken] = useState(startingToken);
	const [profile, setProfile] = useState({name:'', email:'', role:'', projects:[]});
	const [collapsed, setCollapsed] = useState(false);		

	const logIn = async (data) => {
		try {
            const response = await axios.post(`${process.env.REACT_APP_API}/authenticate`, data);
			console.log('Response', response)
            const token = (response).data.token;
            storeToken(token);
            setToken(token);
            setIsAuth(true);
            return await Promise.resolve({
                statusCode: response.status,
                message: "OK",
                success: true
            });
        } catch (err) {
            return await Promise.resolve({
                statusCode: err.response.status,
                message: err.message,
                success: false
            });
        }
	};

	const logOut = () => {
		
		removeToken();
		setToken(null);
		setIsAuth(false);
	};

	const getHeader = () => {			
		return '';
	};

	// const googleLogIn = (data, project) => {

	// 	if (!data.code) {
	// 		return Promise.resolve(false);
	// 	}

	// 	const googleAuthToken = data.code;
	// 	const config = {
	// 		headers: {
	// 			'Authorization': 'Bearer ' + token
	// 		}
	// 	}

	// 	return axios.post(
	// 			`${process.env.REACT_APP_API}/users/principal/google/authenticate`,
	// 			{ authCode: googleAuthToken, project: project },
	// 			config
	// 		).then(() => Promise.resolve(true))
	// 		.catch(() => Promise.resolve(false));
	// }

	// const googleLogOut = (project) => {

	// 	const config = {
	// 		headers: {
	// 			'Authorization': 'Bearer ' + token
	// 		}
	// 	}

	// 	return axios.post(
	// 			`${process.env.REACT_APP_API}/users/principal/google/unauthenticate`,
	// 			{ project: project },
	// 			config
	// 		).then(() => Promise.resolve(true))
	// 		.catch(() => Promise.resolve(false));
	// }

	return {
		token,
		isAuth,
		logIn,
		logOut,
		// googleLogIn,
		// googleLogOut,
		getHeader,
		profile,
		setProfile,
		collapsed,
		setCollapsed
	}
}