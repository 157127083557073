import { Breadcrumb, Table } from "antd"
import contractData from "../db.json"
import { Link, useParams } from "react-router-dom"
import { CaretDownOutlined, CaretUpOutlined, RightCircleOutlined } from "@ant-design/icons";
import { EntityLabel } from "../components/atoms/EntityLabel";


export const Inventario = () => {
    
    const { contractId } = useParams();


    const columnArticleMock = [
        {
            title: 'Nombre',
            dataIndex: 'productName',
            key: 'productName',
            render: (_, record) => {
                return <Link to={`/contratos/contrato/${contractId}/lingote/${record.id}`} style={{ display: 'flex', flexDirection: 'column', fontSize: '12px' }}>
                    {record.productName}
                </Link>
            },
            
        },
        {
            title: 'Código',
            dataIndex: 'barcode',
            key: 'barcode',
            render: (_, record) => {
                return <small>{record.barcode}</small>
            },

        },
        {
            title: 'Coste',
            key: 'coste',
            dataIndex: 'coste',

            render: (_, record) => {
                return <small>{record.coste}€</small>
            },

        },
        {
            title: 'Venta',
            dataIndex: 'venta',
            key: 'venta',
            render: (venta) => {
                return venta > 0 ? <small>{venta}€</small> : null;
            }
        },
        {
            title: '',
            dataIndex: 'beneficio',
            key: 'beneficio',
            render: (_, record) => (
                <>
                    <small>
                        <div>{(record.beneficio !== 0) && (
                            (record.beneficio > 0) ? <div style={{ color: 'green' }}><CaretUpOutlined />{record.beneficio}%</div> : <div style={{ color: 'red' }}><CaretDownOutlined />{record.beneficio}%</div>
                        )}

                        </div>
                    </small>
                </>
            ),
        },
    ]

    return (
        <div className="general-container">
                <Breadcrumb
            style={{ fontSize: '20px', marginBottom:'20px' }}
            separator=">"
            items={[
                {
                    title: <Link to="/contratos">Mis contratos</Link>,
                },
                {
                    title: <Link to={`/contratos/contrato/${contractId}`}><EntityLabel prefix="Contrato" id={contractId} /></Link>,
                },
                {
                    title: 'Inventario',
                },
            ]}
        />
            <Table

style={{ marginBottom: '20px' }} size='small' dataSource={contractData.inventarioResponseList} columns={columnArticleMock} rowKey={'id'} loading={false} />
        </div>
    )
}