import { Card, Divider, Select, Spin, Tooltip } from "antd"
import { Column } from '@ant-design/plots';
import { InfoCircleFilled } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch";
import { formatDate } from "../utils/formatDate";
import DownloadLink from "../components/atoms/DownloadFile";
import { stringToColor } from "../components/atoms/ColorLabel";
import moment from "moment";

export const Invoices = () => {

    const { data } = useFetch(`${process.env.REACT_APP_API}/clientInvoices`, {}, []);
    const { data: lastInvestment, isLoading: lastInvestmentLoading } = useFetch(`${process.env.REACT_APP_API}/clientInvoices/latest`, {}, []);
    const { data: last12months, isLoading: last12monthsLoading } = useFetch(`${process.env.REACT_APP_API}/clientInvoices/last12months`, {}, []);

    const config = {
        data:last12months,
        xField: 'x',
        yField: 'value',
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: true,
                formatter: (value) => {return moment(value, 'MM/YYYY').format('MMM YY');}
            },
        },
        yAxis: {
            label: {
                //formatter: (value) => `${value}€`
            }
        },
        columnStyle: {
            radius: [20, 20, 0, 0],
        },
        meta: {
          value: {
            alias: 'Cantidad',
            formatter: (value) => `${value}€`
          },          
        }
    };

    return (

        <div className="general-container"><h1>Facturas</h1>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '25px' }}>

                {last12months.length > 0 && 
                    <Card>
                        <Column {...config} loading={last12monthsLoading}/>                
                    </Card>
                }
                
                {lastInvestment &&
                    <Card>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <h2>Última factura </h2>                                
                                <span style={{ marginRight: '10px', color: stringToColor(lastInvestment?.investment?.contractId) }}></span>                                
                            </div>
                            <Divider/>
                        </div>
                        <h3>Importe <span>{lastInvestment?.investment?.revenue.toFixed(2)}€</span></h3>
                        <div style={{ margin: '15px 0px' }}>
                            <b>Fecha de vencimiento</b>
                            <p>{formatDate(lastInvestment?.investment?.start)}</p>
                            <b>Estado de facturación</b>
                            <p style={{ color: 'var(--primary)' }}>{lastInvestment?.statusDescription}</p>
                        </div>

                        {/*<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {lastInvestment?.fileName?.length > 0 &&
                                <><b>Ver factura</b>
                                <DownloadLink action={'open'} filename={lastInvestment?.fileName} entity={"CLIENTPAYMENTS"} tabName={"INVOICES"} entityId={lastInvestment?.payments?.id} />
                                </>
                            }
                        </div>*/}
                    </Card>                    
                } 

                {data.length > 0 && <Card>
                    <div>
                        <h2>Histórico de facturas</h2>
                    </div>
                    
                    {data.map((f) => {
                        return <>
                                <Divider/>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <span style={{ marginRight: '10px', color: stringToColor(f.investment?.contractId) }}></span>                                        
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}><p style={{ color: 'var(--primary)' }}>{f.statusDescription}</p>
                                        {f.fileName?.length > 0 &&
                                            <DownloadLink action={'open'} filename={f.fileName} entity={"CLIENTPAYMENTS"} tabName={"INVOICES"} entityId={f.investment?.id} />
                                        }
                                    </div>
                                </div>
                            </>
                    })}
                </Card>}
            </div>
        </div>
    )
}