import { ContractDetail } from "../pages/ContractDetail";
import { ProductTypes } from "../pages/ProductTypes";
import { Contracts } from "../pages/Home";
import { Documents } from "../pages/Documents";
import { Inventario } from "../pages/Inventario";
import { Inversion } from "../pages/Inversion";
import { InvoiceInformation } from "../pages/InvoiceInformation";
import { Invoices } from "../pages/Invoices";
import { Login } from "../pages/Login";
import { Market } from "../pages/Market";
import { MarketRatesByMetal } from "../pages/MarketRatesByMetal";
import { PurchaseRequest } from "../pages/PurchaseRequest";
import { Pagos } from "../pages/Pagos";
import { Profile } from "../pages/Profile";
import Register from "../pages/Register";
import { SlugInfo } from "../pages/SlugInfo";
import { Notifications } from "../pages/Notifications";

export const routes = [
    {
        path: '/',
        component: Contracts,
        viewHeader: false,
        isAuthenticated: true,
        exact: true
    },
    {
        path: '/login',
        component: Login,
        viewHeader: false,
        isAuthenticated: false,
        exact: true
    },
    {
        path: '/register',
        component: Register,
        viewHeader: false,
        isAuthenticated: false,
        exact: true
    },
    {
        path: '/contratos',
        component: Contracts,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/tipos',
        component: ProductTypes,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/tipos/:product/:productId',
        component: PurchaseRequest,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/contrato/:contractId',
        component: ContractDetail,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: 'contratos/contrato/:contractId/lingote/:id',
        component: SlugInfo,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/contrato/:contractId/inversion',
        component: Inversion,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/contrato/:contractId/pagos',
        component: Pagos,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/contratos/contrato/:contractId/inventario',
        component: Inventario,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/market',
        component: Market,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/cotizaciones/:metal',
        component: MarketRatesByMetal,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/documentos',
        component: Documents,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/facturas',
        component: Invoices,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/perfil',
        component: Profile,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/facturacion',
        component: InvoiceInformation,
        viewHeader: true,
        isAuthenticated: true,
    },
    {
        path: '/notificaciones',
        component: Notifications,
        viewHeader: true,
        isAuthenticated: true,
    },

]


