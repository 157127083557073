import React from 'react';
import { CaretUpFilled, CaretDownFilled } from '@ant-design/icons';

const PercentageCustom = ({ increment }) => {
  let color = 'black';

  if (increment > 0) {
    color = 'green';
  } else if (increment < 0) {
    color = 'red';
  }

  return (
    <span style={{ color, marginLeft:'5px' }}>
      {increment !== 0 ? (increment > 0 ? <CaretUpFilled /> : <CaretDownFilled />) : null}
      {increment?.toFixed(2)}%
    </span>
  );
};

export default PercentageCustom;
