import { Affix, ConfigProvider, Layout as AntdLayout, Layout } from 'antd';
// import es_ES from "antd/lib/locale/es_ES";
// import 'moment/locale/es';
import CustomHeader from '../../components/organism/CustomHeader';
import { CustomFooter } from '../../components/organism/CustomFooter';
import { useAuth } from '../../store/AuthContext/AuthContext';
import MainContent from './MainContent';


const MainLayout2 = () => {
  
  const { isAuth } = useAuth();  

  return (
    <Layout style={{ minHeight: '100vh', overflow: 'auto' }}>
   
    {isAuth && <CustomHeader isAuthenticated={isAuth} viewHeader={true}/>}
							
    <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#EA9E0C'
      },
    }}
  > 
        <MainContent/>
        
      </ConfigProvider>
      {
          isAuth && <Affix >

        <CustomFooter>                
        
      </CustomFooter>
      </Affix>

      }
    </Layout>
	)
};

export default MainLayout2;