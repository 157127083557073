import { Breadcrumb, Button, Card, Col, Divider, Form, Input, InputNumber, Row } from "antd"
import {DoubleLeftOutlined} from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import TextArea from "antd/es/input/TextArea"
import { customRequest } from "../common/request/customRequest";
import { useFetch } from "../hooks/useFetch";
import tarjeta from "../assets/tarjeta.jpeg"

export const PurchaseRequest = () => {

    const { product, productId } = useParams();

    const [form] = Form.useForm();


    const sumbitRequest = async (values) => {

        const data = {
            productName: product,
            productId: productId,
            comment: values.comment,
            investment: values.investment
        }

        await customRequest(`${process.env.REACT_APP_API}/clientRequest`, { method: 'POST', data: data }, "Solicitud enviada").then(() => {
            form.resetFields()
        })
    }

    const { data: offers } = useFetch(`${process.env.REACT_APP_API}/productOffers/active/${productId}`, {}, []);
    const { data } = useFetch(`${process.env.REACT_APP_API}/productsClient/${productId}`, {}, []);

    return (<div className="general-container">


        {/* <Breadcrumb
            style={{ fontSize: '20px' }}
            separator=">"
            items={[
                {
                    title: <Link to="/contratos/tipos">Tipos de Productos</Link>,
                },
                // {
                //     title: `Solicitud - ${product}`,
                // },
            ]}
        /> */}

        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {/* <Link to={'/contratos/tipos'}><DoubleLeftOutlined /><span style={{ fontSize: '16px' }}> Atrás</span> </Link> */}
            <Button size='small' style={{ display: 'flex', justifyContent:'center', alignItems:'center', color: 'var(--dark-accent)', borderColor: 'var(--dark-accent)', fontSize: '15px', backgroundColor:'transparent' }}><Link to={'/contratos/tipos'}><span> Atrás</span> </Link> </Button>
        </div>

        <Card size='small' key={data.id} style={{ margin: '10px 0px', borderTop: '3px solid var(--accent-variant)', paddingTop: '10px' }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '0.5px solid lightgray', }}>

                <img src={tarjeta} style={{ width: '140px' }} />
                <div style={{ padding: '20px' }}>
                    <h2 style={{ fontWeight: 500, fontStyle: 'italic', marginBottom: '5px' }}>{data.name}</h2>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <a style={{ fontWeight: 500, fontStyle: 'italic' }} target="blank" href={data.url}>Ver Info</a>
            </div>
        </Card>
        <Form layout='vertical' style={{
            border: '1px solid var(--grey)',
            padding: '20px',
            marginTop: '30px',
            borderRadius: '25px'
        }} onFinish={sumbitRequest}
            form={form}>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item label={'Inversión'} name='investment' rules={[{ required: true }]}>
                        <InputNumber style={{ width: '100%' }} controls={false} addonAfter="€" min={0} step={0.1} />
                    </Form.Item>

                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} >
                    <Form.Item label='Comentario' name='comment' rules={[{ required: true }]}>
                        <TextArea placeholder="¿Qué necesitas?" rows={4} />
                    </Form.Item>
                </Col>

            </Row>

            {offers.length > 0 ?
                offers.map((of) => {
                    return <Card size="small" style={{ background: "var(--accent)", margin: '20px 0px' }} >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <b>{of.name}</b>
                            <Divider style={{ backgroundColor: 'black', margin: '15px 0px' }} />
                            <small>{of.description}</small>
                        </div>
                        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ fontSize: '11px', fontWeight: '600' }}><Link style={{ color: 'black' }} to={'/contratos/tipos'}>VER MÁS {">"}</Link></p>
                </div> */}
                    </Card>
                })
                : null}

            <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
                <Button onClick={() => form.submit()} color="yellow" >
                    Enviar
                </Button>
            </div>
        </Form>

    </div >
    )
}