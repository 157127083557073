import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./store/AuthContext/AuthContext";
import MainLayout2 from "./layouts/main/MainLayout";

function App() {
  return (
    <BrowserRouter>
    <AuthProvider>
    
  {/* <MainLayout /> */}
  <MainLayout2/>
   </AuthProvider>
  </BrowserRouter>
  );
}

export default App;
