import React, { createContext, useContext } from 'react';
import { useAuthProvider } from '../../hooks/useAuthProvider/useAuthProvider';


const authContext = createContext({
	token: null ,
	project: '' ,
	setProject: null ,
	profile: null ,
	setProfile: null ,
	isAuth: false,
	logIn: (data) => Promise.resolve(),
	logOut: () => {},
	getHeader: () => {},
	groupAccount: null ,
	setGroupAccount: null ,
	googleLogIn: (data, project) => Promise.resolve(false),
	googleLogOut: (project) => Promise.resolve(false),
	collapsed: true,
	setCollapsed: null 
} );

const AuthProvider = ({ children }) => {

	const auth = useAuthProvider();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

const useAuth = () => {
	return useContext(authContext);
}

export {
	AuthProvider,
	useAuth
};
