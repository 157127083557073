import ColorLabel from "./ColorLabel";

export const EntityLabel = ({ prefix = '', id = '' }) => {
     
    const formattedId =  id.slice(-5, id.length)

    return (
      <div>
        <p>{prefix} {formattedId}</p>
        {/* <ColorLabel idProp={id}/> */}
      </div>
    );
}