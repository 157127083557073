import { Card } from "antd"
import { RightOutlined } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch";

export const Profile = () => {

    const { data: user } = useFetch(`${process.env.REACT_APP_API}/appClient`, {}, []);

    return (
        <div className="general-container">
            <h1>Mi perfil</h1>
            <div style={{display:'flex', flexDirection:'column', gap:'20px', marginTop:'20px'}}>
            <Card>
                <h2>Datos personales</h2>

                <div style={{margin:'15px 0px'}}>
                    <b style={{color:'var(--primary)'}}>Titular</b>
                    <p>{user.name}</p>
                </div>

                <div>
                    <b style={{color:'var(--primary)'}}>Documento de identidad</b>
                    <p>{user.fiscalId}</p>
                </div>
            </Card>

            <Card>
                <h2>Datos de contacto</h2>

                <div style={{margin:'15px 0px'}}>
                    <b style={{color:'var(--primary)'}}>Email</b>
                    <p>{user.email}</p>
                    {/* <b style={{color:'var(--primary)'}}>CAMBIAR EMAIL <RightOutlined /> </b>  */}
                </div>

                <div>
                    <b style={{color:'var(--primary)'}}>Teléfono de contacto</b>
                    <p>{user.phone}</p>
                    {/* <b style={{color:'var(--primary)'}}>CAMBIAR NÚMERO DE CONTACTO <RightOutlined /> </b>  */}

                </div>
            </Card>
            </div>
        </div>
    )
}