import { Layout as AntdLayout } from 'antd';
import AppRoutes from '../../routing/AppRoutes';


const { Content } = AntdLayout;

const MainContent = () => {

	return (
		<Content  style={{
            
            minHeight: 'calc(100vh - 100px)',
        }}>
			<AppRoutes />		
		</Content>
	)
};

export default MainContent;