import { Footer } from "antd/es/layout/layout"
import { SignalFilled, HomeFilled, SnippetsFilled, ShoppingFilled } from '@ant-design/icons';

import "../../styles/CustomFooter.css"
import { Link } from "react-router-dom";

export const CustomFooter = () => {
    return (
        <Footer className="custom-footer" >
            <Link className="link-footer" to={"/contratos"}>
                <HomeFilled />
                Inicio
            </Link>
            <Link className="link-footer" to={"/market"}>
            <SignalFilled />                
            Mercado
            </Link>
            <Link className="link-footer" to={"/facturas"}>
                <SnippetsFilled />                
                Facturas
            </Link>
            <Link className="link-footer" to={"/contratos/tipos"}>
                <ShoppingFilled />
                Productos
            </Link>
        </Footer>
    )
}