import { Button, Divider, Drawer } from "antd"
import { PoweroffOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import { useAuth } from "../../store/AuthContext/AuthContext";
import img from "../../assets/logos_silvergold_1_Mesa_de_trabajo_1.png";

export const SideMenu = ({ isMenuVisible, setIsMenuVisible }) => {


    const { profile } = useAuth();

    const { logOut } = useAuth();

    return (
        <Drawer
            title={

                <>
                   

                    <div style={{display:'flex', justifyContent:'flex-end'}} onClick={() => { logOut() }}>
                        <PoweroffOutlined />
                    </div>

                </>
            }
            onClose={() => setIsMenuVisible(null)}
            placement='left'
            width={300}
            open={!!isMenuVisible}
        >

             <div style={{display:'flex', justifyContent:'center'}}>
                <img src={img} style={{width:'100px'}} />
                </div>

            <div style={{ display: 'flex', alignItems: 'center', height: '80px', gap: '10px' }}>
                <div className='container-logo flex-center'>
                    <div className='container-circle-logo flex-center'>
                        <span className='circle fw-bold'>
                            {profile.name && profile.name.toUpperCase().split('')[0]}
                        </span>
                    </div>
                </div>
                <div>
                    <h2>{profile.name}</h2>
                    <small>{profile.email}</small>
                </div>
            </div>

            <Divider/>
            
            <div style={{ height: '100px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                <Link style={{ color: 'black' }} to={'/perfil'} onClick={() => { setIsMenuVisible(false) }}><b>Datos personales</b></Link>
                <Link style={{ color: 'black' }} to={'/facturacion'} onClick={() => { setIsMenuVisible(false) }}><b>Datos de facturación</b></Link>
                {/*<Link style={{ color: 'black' }} to={'/documentos'} onClick={() => { setIsMenuVisible(false) }}><b>Mis documentos</b></Link>

                {/* <Button size='small'  shape="round"  type="primary" style={{width:'50px', backgroundColor:'var(--black)'}} onClick={() => { logOut() }}>
                        <PoweroffOutlined />
                </Button> */}
            </div>
        </Drawer>
    )
}