import { Card } from "antd";
import { useFetch } from "../hooks/useFetch";

export const Notifications = () => {

    const { data: storesData } = useFetch(`${process.env.REACT_APP_API}/client/notifications`, {}, []);

    return (
        <Card style={{ border: 'none', background: 'white' }} bodyStyle={{ padding: "20px" }}>
           
            <h1>Notificaciones</h1>

            <div style={{padding:'10px 20px'}}>
                <ul>
                    {storesData?.notificationList?.map((n) => {
                        return <li><div><h3>{n.title}</h3>{n.content}</div></li>
                    })}
                </ul>
            </div>
        </Card>
    )
}