import { Breadcrumb, Col, Divider, Progress, Row, Timeline, Table, Tag, Tabs, Tooltip, Button } from "antd"
import { CheckCircleOutlined, SyncOutlined, DoubleLeftOutlined, EuroOutlined, CaretDownOutlined, GoldOutlined } from "@ant-design/icons"
import { Link, useParams } from "react-router-dom"
import ContractDuration from "../components/atoms/ContractDuration"
import "../styles/ContractDetail.css"

import { useFetch } from "../hooks/useFetch";
import { useEffect, useState } from "react";
import { formatPrice } from "../utils/formatPrice";
import moment from "moment";
import { INVESTMENT_STATUS } from "../common/constants/investmentStatus"
import PercentageCustom from "../components/atoms/PercentageCustom"
import { INVESTMENT_PLAN } from "../common/constants/investmentPlan"
import { INVESTMENT_TYPE } from "../common/constants/investmentType"


const DRAWERS_TABLE_ROWS = 8;

export const ContractDetail = () => {


  const { contractId } = useParams();

  const { data } = useFetch(`${process.env.REACT_APP_API}/contractsClient/${contractId}/investments`, {}, []);

  const [contract, setContract] = useState(null);

  useEffect(() => {
    setContract(data?.contract?.contractInfo)

  }, [data])

  const investmentsColumns = [
    {
      title: '',
      dataIndex: 'type',
      align: 'center',
      width: '10%',
      render: (_, record) => {
        return <div className="item-view-product">

          {moment(record.start).isBefore(moment.now()) &&
            INVESTMENT_TYPE[record.type] && (
              <>
                <Tag color={INVESTMENT_TYPE[record.type].color} style={{ borderRadius: '8px', minWidth:'130px', textAlign:'center' }}>
                  {INVESTMENT_STATUS[record.status] && (
                    record.status === 'DONE' ?
                      <span style={{marginRight:'5px'}}><CheckCircleOutlined /></span>
                      :
                      <span style={{marginRight:'5px'}}><SyncOutlined spin /></span>)}
                  {INVESTMENT_TYPE[record.type].name} {formatPrice(record.revenue, 2)}€
                </Tag>
                <div><small>{moment(record.start).format('ll')}</small></div>
              </>
            )}
        </div>
      }
    },
    {
      title: 'Saldo Metálico',
      dataIndex: 'contractCashAccount',
      align: 'center',
      render: (_, record) => (
        <>
          <small style={{ fontWeight: 700 }}>{formatPrice(record.contractCashAccount, 2)}€</small>
        </>
      ),
    },
    {
      title: 'Saldo Metal',
      dataIndex: 'contractUnitsCostAccount',
      align: 'center',
      render: (_, record) => (
        <Tooltip title={`${record.units} lingotes`}>
          <small style={{ fontWeight: 700 }}>{formatPrice(record.contractUnitsCostAccount, 2)}€
          </small>
        </Tooltip>
      ),
    },
 
  ];

  const inventoryColumns = [
    {
      title: 'Barcode',
      dataIndex: 'barCode',
      key: 'barCode',
      align: 'center',
      render: (_, record) => {
        return <>
          <a>#{record.barCode}</a>
        </>
      }
    },
    {
      title: 'Coste',
      key: 'coste',
      dataIndex: 'coste',
      align: 'center',
      render: (_, record) => {
        return <>{record.cost}€</>
      },
    },
    {
      title: 'Nombre',
      dataIndex: 'productName',
      key: 'productName',
      align: 'center',
      render: (_, record) => {
        return <>
          <div>{record.productType.name}</div>
          <a><small>{record.productType.sku}</small></a>
        </>

      },
    },
    {
      title: 'Almacén',
      key: 'store',
      dataIndex: 'store',
      align: 'center',
      render: (_, record) => {
        return <>{record.store}</>
      },
    },
    {
      title: '',
      key: 'onSale',
      align: 'center',
      render: (_, record) => {
        return <>{record.onSale && <Tag color='lime'>En venta</Tag>}</>
      },
    }
  ]

  const investmentsTab = data?.investments || [];
  const inventoryTab = data?.inventory || [];

  const items = [
    {
      key: '1',
      label: 'Operaciones',
      children: <Table size='small' dataSource={investmentsTab} columns={investmentsColumns} rowKey={'id'} loading={false}
        pagination={{
          defaultPageSize: DRAWERS_TABLE_ROWS,
        }}
      />,
    },
    {
      key: '2',
      label: 'Inventario',
      children: <Table size='small' dataSource={inventoryTab} columns={inventoryColumns} rowKey={'id'} loading={false}
        pagination={{
          defaultPageSize: DRAWERS_TABLE_ROWS,
        }} />,
    },
  ];


  return (<div className="general-container">

    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      {/* <Link to={'/contratos'}><DoubleLeftOutlined /><span style={{ fontSize: '16px' }}> Atrás</span> </Link> */}
      <Button size='small' style={{ display: 'flex', justifyContent:'center', alignItems:'center', color: 'var(--dark-accent)', borderColor: 'var(--dark-accent)', fontSize: '15px', backgroundColor:'transparent' }}><Link to={'/contratos'}><span> Atrás</span> </Link> </Button>
      
    </div>

    <div className="container-kpi">
      <div className="card-kpi" style={{ width: '100px', height: '75px' }}>
        <div className='value-kpi' >
          <b>{contract?.revenue}€</b>
          <small>{<ContractDuration days={contract?.contractDuration} />}</small></div>

      </div>

      <div className="card-kpi" style={{ width: '250px', height: '75px' }}>
        <div className='value-kpi'>
          {contract?.typeDescription}
        </div>
        <div className='value-kpi' >{contract?.walletDescription}</div>

      </div>
    </div>

    <div>
      <div className="container-info-client">
        <div style={{ marginBottom: '5px' }}>

          <Row gutter={[16, 16]} >

            {
              contract?.investmentPlan === INVESTMENT_PLAN.TOTAL_FREEDOM &&
              <><Col span={15}>
                <div>
                  <b>Gestor </b>
                  <p>{contract?.commercialName}</p>
                </div>
              </Col><Col span={9}>
                  <b>Inicio </b>
                  <p>{moment(contract?.start).format('ll')}</p>
                </Col></>
            }
          </Row>
        </div>
        {contract?.investmentPlan !== INVESTMENT_PLAN.TOTAL_FREEDOM &&
          <>
            <Col span={15}>
              <div>
                <b>Gestor </b>{contract?.commercialName}
              </div>
            </Col>
            <Progress percent={contract?.progress} /><div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <small>
                  <b>Inicio </b>  {moment(contract?.start).format('ll')}
                </small>
              </div>
              <div>
                <small>
                  <b>Fin: </b>  {moment(contract?.end).format('ll')}
                </small>
              </div>
            </div></>}
      </div>
    </div>

    <div className="container-kpi">
      <div className="card-kpi" style={{ width: '100px', height: '85px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}  >
          <b >Pagado</b>
          <p>
            {formatPrice(data?.contract?.paid)}€</p>

        </div>

      </div>

      <div className="card-kpi" style={{ width: '250px', height: '85px' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }} >
          <b style={{ padding: '5px 0px' }}>Saldo</b>
          <div>
            <p>
              <EuroOutlined style={{ marginRight: '5px' }} />{formatPrice(data?.contract?.accountRevenue)}€
              <PercentageCustom increment={data?.contract?.benefit} />
            </p>
            <p>
              <GoldOutlined style={{ marginRight: '5px' }} /><span style={{ color: 'grey' }}>{formatPrice(data?.contract?.articlesCost)}€</span></p>
          </div>
        </div>

      </div>
    </div>


    <Tabs defaultActiveKey="1" items={items} />

  </div>
  )
}