import { Alert, Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '../store/AuthContext/AuthContext';
import { authRequest } from '../common/request/authRequest';
import '../styles/Register.css'
import img from "../assets/logos_silvergold_1_Mesa_de_trabajo_1.png"
import { Link } from 'react-router-dom';


const Register = () => {

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { logIn } = useAuth();

  const [formError, setFormError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onFinishRegister = async (values) => {
    const data = {
      ...values,
      email:values.email,
      status: 'REGISTERED',
      verificationToken: values.token
    };

    const loginData = {
      username: values.email,
      password: values.password
    };

    authRequest(`${process.env.REACT_APP_API}/users/register?verificationToken=${values.token}`, { method: 'PUT', data })
      .then(() => {
        logIn(loginData);
        message.success('Te has registrado con éxito', 3);
        navigate('/contratos');
        setFormError(false);
      }).catch((error) => {
        setFormError(true);
        !!error.data.message ?
          setErrorMessage(error.data.message) : setErrorMessage('Error al intentar registrar el usuario');
      }
      );
  };



  return (

    <div className='register-general'>

      <div class="register-general-top"></div>

      <div style={{top:'10%', position: 'absolute', display:'flex', flexDirection:'column'}}>
        <h1 style={{color:'white', marginTop:'0px', fontFamily: 'var(--font-family)', fontSize:'45px'}}>SilverGold</h1>
        <p style={{color:'white', marginTop:'0px', fontFamily: 'var(--font-family)'}}>
          Registro a la APP
        </p>
      </div>
    

      <div className='register-general-right'>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinishRegister}
          autoComplete="off"
          className='register-form'
        >
          <Form.Item name='name' label='Nombre'
            rules={[{
              required: true,
              type: 'string',
              message: 'Escriba su nombre completo y apellidos (numeros no permitidos)',
              pattern: new RegExp(/^([^0-9]*)$/)
            }]}>
            <Input placeholder='Escriba su nombre'
            />
          </Form.Item>
          <Form.Item name='email' label='Email' rules={[{ required: true }]} >
            <Input placeholder='Inserte el email'
            />
          </Form.Item>

          <Form.Item name='token' label='Token' rules={[{ required: true }]} >
            <Input placeholder='Inserte el token del email'
            />
          </Form.Item>
          
          <Form.Item name='password' label='Contraseña'
            rules={[
							{
							  required: true,
							  message: 'La contraseña es un campo obligatorio',
							},
							{
							  min: 8,
							  message: 'Debe tener al menos 8 caracteres',
							},
							{
								pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+={}\[\]:;"'<>,.?/~`|\\-]*$/,
								message: 'Debe contener letras y números',
							},
							{
							  pattern: /^(?=.*[a-z])(?=.*[A-Z])/,
							  message: 'Debe contener mayúsculas y minúsculas',
							},
						  ]}>
          
            <Input.Password autoComplete='new-password' placeholder='Introduce la contraseña' />
          </Form.Item>

          <Form.Item name='confirm-password' label='Confirmar contraseña'
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Confirmar la contraseña es un campo requerido'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('La contraseña debe coincidir con el campo anterior'));
                },
              }),
            ]}>
            <Input.Password autoComplete='confirm-password' placeholder='Confirmar la contraseña' />
          </Form.Item>

          <Button className='buttonStyle' htmlType="submit">
            Registrar
          </Button>

          <br/><br/>
          <small><Link to='/login'><b style={{fontSize:'14px', color:'var(--black)'}}>Volver al login</b></Link></small>
        </Form>
        {
          formError && <Alert 
          type='error' 
          style={{ marginTop: '12px', borderRadius: '10px' }}
          message={errorMessage} />
        }
      </div>
      <div style={{position:'absolute', bottom:10, right:10}}>
          <img src={img} style={{width:'80px'}} />
        </div>
    </div>
  )
}

export default Register;