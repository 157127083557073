import { Badge, Breadcrumb, Button, Card, Table, Tag, Tooltip } from "antd"
import { Link } from "react-router-dom"
import { useFetch } from "../hooks/useFetch"
import tarjeta from "../assets/tarjeta.jpeg"

export const ProductTypes = () => {

    const { data } = useFetch(`${process.env.REACT_APP_API}/productsClient`, {}, []);

    const { data: requestData, isLoading } = useFetch(`${process.env.REACT_APP_API}/clientRequest`, {}, []);

    const columns = [
        {
            title: 'Producto',
            dataIndex: 'productName',
            key: 'productName',
        },
        {
            title: 'Inversión',
            dataIndex: 'investment',
            key: 'investment',
            render: (investment) => {
                return <p>{investment}€</p>
            }
        },
        {
            title: 'Estado',
            key: 'statusDescription',
            dataIndex: 'statusDescription',
            align: 'center',
            render: (statusDescription) => {

                return (
                    <Tag>{statusDescription}</Tag>
                )
            }
        }
    ]

    return (

        <div className="general-container"><Breadcrumb
            style={{ fontSize: '20px', marginBottom: '30px' }}
            separator=">"
            items={[
                {
                    title: 'Tipos de Productos',
                },

            ]} />

            {data &&
                data.map((product) => (
                    product?.titleOffers?.length > 0 ? (
                        <Badge.Ribbon
                            key={product.id}
                            text={<Tooltip title={product.titleOffers.join(' , ')}>¡OFERTA!</Tooltip>}
                            color="var(--accent-variant)"
                        >
                            <Card size='small' key={product.id} style={{ margin: '10px 0px', borderTop: '3px solid var(--accent-variant)', paddingTop: '10px' }}>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '0.5px solid lightgray', }}>

                                    <img src={tarjeta} style={{ width: '140px' }} />
                                    <div style={{ padding: '20px' }}>
                                        <h2 style={{ fontWeight: 500, fontStyle: 'italic', marginBottom: '5px' }}>{product.name}</h2>
                                        <Button style={{ color: 'rgb(196, 136, 8)', borderColor: 'rgb(196, 136, 8)' }}><Link to={`/contratos/tipos/${product.name}/${product.id}`}>Solicitar Producto</Link></Button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <a style={{ fontWeight: 500, fontStyle: 'italic' }} target="blank" href={product.url}>Ver Info</a>
                                </div>
                            </Card>
                        </Badge.Ribbon>
                    ) : (
                        <Card size='small' key={product.id} style={{ margin: '10px 0px', borderTop: '3px solid var(--accent-variant)', paddingTop: '10px' }}>

                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '0.5px solid lightgray', }}>

                                <img src={tarjeta} style={{ width: '140px' }} />
                                <div style={{ padding: '20px' }}>
                                    <h2 style={{ fontWeight: 500, fontStyle: 'italic', marginBottom: '5px' }}>{product.name}</h2>
                                    <Button style={{ color: 'var(--dark-accent)', borderColor: 'var(--dark-accent)' }}><Link to={`/contratos/tipos/${product.name}/${product.id}`}>Solicitar Producto</Link></Button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <a style={{ fontWeight: 500, fontStyle: 'italic' }} target="blank" href={product.url}>Ver Info</a>
                            </div>
                        </Card>
                    )
                ))}

            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <h3>Mis solicitudes</h3>
                <Table
                    size="small"
                    dataSource={requestData}
                    columns={columns}
                    loading={isLoading}
                />
            </div>

        </ div>
    )
}