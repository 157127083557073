import { Tag } from "antd"
import { formatPrice } from "../../utils/formatPrice";

export const PriceTag = ({ price, size }) => {
   
    const formatedPrice = formatPrice(Number(price))
  

    return (
      <Tag
        color="white"
        style={{
          color: "black",
          borderColor: "grey",
          borderRadius: "10px",
          fontWeight: "bold",
          fontSize: size
        }}
      >
      {formatedPrice + "€"}
      </Tag>
    );
}