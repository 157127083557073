import { Card } from "antd"
import { Link } from "react-router-dom"
import { useFetch } from "../hooks/useFetch";
import { useAuth } from "../store/AuthContext/AuthContext";
import DownloadLink from "../components/atoms/DownloadFile";

export const Documents = () => {

    const { profile } = useAuth();

    const { data: LOPD } = useFetch(`${process.env.REACT_APP_API}/clientDocuments/CLIENTS/LOPD/${profile.id}`, {}, []);

    const { data: contracts } = useFetch(`${process.env.REACT_APP_API}/clientDocuments/CLIENTS/CONTRACTS/${profile.id}`, {}, []);



    return (
        <div className="general-container">
            <h1>Mis Documentos</h1>

            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: '20px 0px' }}>
                {contracts.length > 0 && <Card>
                    <h2>Contratos</h2>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {contracts.map((c) => {
                            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link>
                                    <b>{c}</b>
                                </Link>
                                <DownloadLink filename={c} entity={"CLIENTS"} tabName={"CONTRACTS"} entityId={profile.id} />
                            </div>
                        })}

                    </div>
                </Card>}
                {LOPD.length > 0 && <Card>
                    <h2>LOPDS</h2>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {LOPD.map((c) => {
                            return <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Link>
                                    <b>{c}</b>
                                </Link>
                                <DownloadLink filename={c} entity={"CLIENTS"} tabName={"LOPD"} entityId={profile.id} />

                            </div>
                        })}


                    </div>
                </Card>}
            </div>
        </div>
    )
}