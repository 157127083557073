import { authRequest } from "../../common/request/authRequest";
import { DownloadOutlined } from "@ant-design/icons";


const DownloadLink = (props) => {
  
	const handleAction = async () => {
		
	authRequest(`${process.env.REACT_APP_API}/clientDocuments/${props.entity}/${props.tabName}/${props.entityId}/${props.filename}`, { method: 'GET', responseType: 'blob',  headers: {
		Accept: props.img ? 'image/*' : 'application/pdf'
	  }})
	.then((data) => {
		
		const urlToFile = window.URL.createObjectURL(data);		
		
		if (props.action && props.action == 'open') {
			window.open(urlToFile);		
		} else {
			const link = document.createElement('a');
			link.href = urlToFile;
			link.target = '_blank'
			link.setAttribute('download', props.filename);
			document.body.appendChild(link);		
	
			link.click();

		}			
	  });
	}

	return(
		<>
			{/* <a role='button' onClick={handleAction}>{props.filename}</a> */}
            <b  onClick={handleAction} style={{ color: '#2d2dc8', display: 'flex', justifyContent: 'flex-end' }}>DESCARGAR <DownloadOutlined /></b>
	  	</>
	  )
}

export default DownloadLink;