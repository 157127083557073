import { Card, Col, Radio, Row } from "antd"
import { Line, TinyArea, TinyLine } from '@ant-design/plots';
import { DemoLine } from "../components/molecules/DemoLine";
import { useFetch } from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import PercentageCustom from "../components/atoms/PercentageCustom";

export const Market = () => {

    const { data: dailyData, isLoading } = useFetch(`${process.env.REACT_APP_API}/market/last72hours`, {}, []);


    const config = {
        height: 30,
        autoFit: true,
        smooth: true,
        smooth: true,
        yAxis: {
			min: 10000
		},
        animation: {
            appear: {
              animation: 'path-in',
              duration: 1500,
            },
          },
    };

    return <div className="general-container">
        <h2 style={{ textAlign: 'center', marginBottom: '10px' }}>Cotizaciones por metal</h2>        

        {(!isLoading && dailyData.length > 0) ? <div style={{ marginTop: '20px' }}>
            {dailyData.map((m) => {
                const valuesArray = m?.last72hours.map((data) => data.value);

                return <Link to={`/cotizaciones/${m.metal}`}>

                    <Row key={m.metal} style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '10px' }}>
                        <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <div style={{ backgroundColor: 'white', border: '1px lightgrey solid', width: '80px', height: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', margin: '10px 0px', paddingTop: '5px' }}>
                                <div style={{ border: '3px solid var(--accent-variant)', borderRadius: '5px', height: '35px', width: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <span style={{ fontWeight: '700', color: 'black' }}>{m.metal}</span>
                                </div>
                                <div style={{ margin: '5px 0px', height: '3px', width: '15px', backgroundColor: 'var(--accent-variant)' }}></div>
                                <b style={{ color: 'black' }}>{m.metalName}</b>
                            </div>
                        </Col>
                        <Col span={17}>
                            <div style={{ backgroundColor: 'white', border: '1px lightgrey solid', width: 'auto', height: '80px', display: 'flex', flexDirection: 'column', borderRadius: '15px', margin: '10px 0px', paddingTop: '5px' }}>

                                <div style={{ marginLeft: '10px' }} >
                                    <div style={{ fontSize: '16px', fontWeight: 700, color: 'var(--dark-accent)' }}>{m.currentQuote.value}€/oz</div>
                                    <PercentageCustom increment={m.increment} />

                                </div>


                                <div style={{ width: '100%' }}>
                                    <TinyLine {...config}
                                    data={valuesArray}

                                    />
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Link>

            })}
        </div> : <Card><div style={{ textAlign: 'center', fontWeight: 600, color: 'grey' }}>No hay datos de cotización</div></Card>}
    </div>
}