import { Breadcrumb, Table, Tag } from "antd";
import contractData from "../db.json"
import { Link, useParams } from "react-router-dom";
import { EntityLabel } from "../components/atoms/EntityLabel";


export const Pagos = () => {

    const { contractId } = useParams();

    const columns = [
        {
            title: 'Fechas',
            dataIndex: 'dates',
            render: (_, record) => (
                <small>
                    {record.start}
                </small>
            ),
        },
       
        {
            title: 'Importe',
            dataIndex: 'revenue',
            render: (_, record) => (
                <>
                    <span style={{ textAlign: 'center' }}>{record.revenue}€</span>
                </>
            ),
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            render: (_, record) => {
                return <div className="item-view-product">
                    {record.status === 'PAID' ?

                        <Tag color="green">{record.statusDescription}</Tag>
                        :
                        <Tag color="yellow">{record.statusDescription}</Tag>
                    }
                </div>
            },
        },
    ];
    

    return (
        <div className="general-container">
                <Breadcrumb
            style={{ fontSize: '20px', marginBottom:'20px' }}
            separator=">"
            items={[
                {
                    title: <Link to="/contratos">Mis contratos</Link>,
                },
                {
                    title: <Link to={`/contratos/contrato/${contractId}`}><EntityLabel prefix="Contrato" id={contractId} /></Link>,
                },
                {
                    title: 'Pagos',
                },
            ]}
        />
        <Table style={{ marginBottom: '20px' }} size='small' dataSource={contractData.paymentResponseList} columns={columns} rowKey={'id'} loading={false} />

        </div>
    )
}