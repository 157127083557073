import { Line } from '@ant-design/plots';
import { CaretUpOutlined, CaretDownOutlined, DoubleLeftOutlined } from "@ant-design/icons";
import { Link, useParams } from 'react-router-dom';
import { Button, Card, Col } from 'antd';
import { authRequest } from '../common/request/authRequest';
import { useEffect, useState } from 'react';

export const MarketRatesByMetal = () => {

	const { metal } = useParams();

	const [last12Months, setLast12Months] = useState(null)
	const [data, setData] = useState(null)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		authRequest(`${process.env.REACT_APP_API}/market/${metal}/last12months`, {}).then((data) => {
			setLast12Months(data.last12Months)
			setData(data)
			setIsLoading(false)
		})
	}, [])


	const chartMonthlyConfig = {
		min: 'auto',
		autoFit: true,
		xField: 'x',
		yField: 'value',
		color: '#00C39A',
		yAxis: {
			min: 50000
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: true,

			},
			tickCount: 6,
		},
		slider: {
			start: 0,
			end: 1,
			trendCfg: {
				isArea: true,
			},
		},
		smooth: false,
	};


	
	
	return (
		<div className="general-container" >
	
			<div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
				{/* <Link to={'/market'}><DoubleLeftOutlined /><span style={{ fontSize: '16px' }}> Atrás</span> </Link> */}
				<Button size='small' style={{ display: 'flex', justifyContent:'center', alignItems:'center', color: 'var(--dark-accent)', borderColor: 'var(--dark-accent)', fontSize: '15px', backgroundColor:'transparent' }}><Link to={'/market'}><span> Atrás</span> </Link> </Button>
			</div>

			<div style={{ marginTop: '10px' }}>

				{/* <h3 className='title-chart'>Cotizaciones en euros por Onza</h3> */}
				<h3 className='title-chart'>Últimos 12 meses</h3>

				{data &&
					<Card style={{ margin: '10px 0px' }} size='small'>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent:'space-between', gap: '5px' }}>
							<div style={{ border: '1px solid lightgray', backgroundColor: 'white', width: '80px', height: '80px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: '15px', margin: '10px 0px', paddingTop: '5px' }}>
								<div style={{ border: '3px solid var(--accent-variant)', borderRadius: '5px', height: '35px', width: '35px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
									<span style={{ fontWeight: '700' }}>{data?.metal}</span>
								</div>
								<div style={{ margin: '5px 0px', height: '3px', width: '15px', backgroundColor: 'var(--accent-variant)' }}></div>
								<b >{data?.metalName}</b>
							</div>
							<div style={{textAlign:'center'}}>
								<b style={{ fontSize: '13px' }}>{data?.previousQuote?.value}€/oz</b>
								<p>Ayer</p>
							</div>
							<div style={{textAlign:'center'}}>
								<b style={{ fontSize: '13px' }}>{data?.currentQuote?.value}€/oz</b>
								<p>Hoy</p>
							</div>

							<div style={{ color: data?.increment >= 0 ? 'green' : 'red', fontSize: '12px' }}>
								{data?.increment >= 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
								{(data?.increment).toFixed(2)}%</div>
						</div>
					</Card>}

				{!isLoading && last12Months && <div style={{ marginTop: '30px' }}>
					<Line data={last12Months} 
					{...chartMonthlyConfig} 
					isLoading={isLoading} />
				</div>}

			</div>
		</div>
	)
}