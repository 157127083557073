import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useAuth } from "../store/AuthContext/AuthContext";
import { routes } from "./constants";


const AppRoutes = () => {

    const { isAuth } = useAuth();

    return (
        <Routes>
            {
                routes.map((route, index) => {
                    return (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            element={
                                !route.isAuthenticated || isAuth ? (
                                  <route.component />
                                ) : (
                                  <Navigate to="/login" />
                                )
                              }
                            >

                            {/* {
                                route.path === "/" ? <Navigate to="/contratos" /> : null
                            } */}
                        </Route>
                    )
                })
            }
        </Routes>
    );
}

export default AppRoutes;